import React from 'react'
import Layout from '../../components/Layout';
import { Container, Card, CardBody, Heading, Text } from '../../components/UI';
import { graphql, Link } from 'gatsby'
import { Helmet } from 'react-helmet'

const BlogIndex = ({ data }) => {
  const posts = data.allMarkdownRemark.nodes.filter(node => node.fields.slug.substring(0, 5) === '/blog');

  if (posts.length === 0) {
    return (
      <Layout>
        <Helmet>
          <title>Blog - SecSafeConsult</title>
          <meta name="description" content="Læs mere omkring virksomheden og forskellige konkrete cases." />
          <meta property="og:title" content="Blog - SecSafeConsult" />
        </Helmet>
        <Container maxWidth="600px" textAlign="center" mx="auto" my="xxxxl" px="xl">
          <Text>Der er endnu ikke udgivet nogle blog posts. Kom tilbage senere.</Text>
        </Container>
      </Layout>
    )
  }

  return (
    <Layout>
      <Helmet>
        <title>Blog - SecSafeConsult</title>
        <meta name="description" content="Læs mere omkring virksomheden og forskellige konkrete cases." />
        <meta property="og:title" content="Blog - SecSafeConsult" />
      </Helmet>
      <Container maxWidth="content" mx="auto" mt={["0", "xxxl"]} mb="xxxxl" px="xl">
        <Heading textAlign="center" highlight="center">Blog posts</Heading>
        {posts.map(post => (
          <Container key={post.fields.slug} maxWidth="600px" mx="auto" my="xxxl">
            <Link to={post.fields.slug} style={{ textDecoration: 'none' }}>
              <Card>
                <Heading type="h4">{post.frontmatter.title}</Heading>
                <Text>{post.frontmatter.date}</Text>
                <CardBody>
                  <Text>{post.excerpt}</Text>
                </CardBody>
              </Card>
            </Link>
          </Container>
        ))}
      </Container>
    </Layout>
  );
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
        }
      }
    }
  }
`